/* eslint-disable import/named,class-methods-use-this,no-console */
import { SOCKETS_URL } from 'services/constants';
import Cookie from 'services/Cookie';
import io from 'socket.io-client';

let socket;

const handleOn = (name, callback) => {
  return (
    socket &&
    socket.on(name, data => {
      // console.log('WS on:', name, data);
      callback(data);
    })
  );
};

const on = (name, callback) => {
  if (Array.isArray(name)) {
    return name.map(n => handleOn(n, callback));
  }

  return handleOn(name, callback);
};

const emit = (name, data) => {
  // console.log('WS emit:', name, data);
  return socket && socket.emit(name, data);
};

const disconnect = () => {
  if (socket) {
    socket.disconnect();
  }
};

const isConnected = () => {
  return !!socket?.connected;
};

const connect = () => {
  if (socket) {
    socket.disconnect();
  }
  const token = Cookie.get('token');
  const company = Cookie.get('company');
  const impersonate = Cookie.get('impersonate');
  socket =
    token &&
    company &&
    io(
      `${SOCKETS_URL}/${company._id}?auth_token=${token.hash}${
        impersonate ? `&impersonate=${impersonate}` : ''
      }`,
      {
        transports: ['polling', 'websocket'],
        path: `/${company._id}`,
        reconnect: true,
        secure: true,
        cookie: true,
        reconnection: true,
        reconnectionDelay: 10000,
        reconnectionDelayMax: 30000,
        reconnectionAttempts: Infinity,
      },
    );
  socket?.connect();
};

const initialized = () => !!socket;

export default { connect, disconnect, emit, initialized, isConnected, on };
