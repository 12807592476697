export const MESSAGE_TYPES = {
  ACKNOWLEDGE: 'acknowledge',
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  FILE: 'file',
  MULTIPLE: 'multiple',
  FORM: 'form',
};

const messageTypesRegex = [
  { type: MESSAGE_TYPES.IMAGE, regExp: /^image/g },
  { type: MESSAGE_TYPES.AUDIO, regExp: /^audio/g },
  { type: MESSAGE_TYPES.VIDEO, regExp: /^video/g },
];
const DEFAULT_ATTACHMENT_TYPE = { type: MESSAGE_TYPES.FILE };

const getTypeFromAttachments = ([attachment]) => {
  const mimeType = attachment.mimeType || attachment.meta?.mimeType || '';
  const matchFromRegex = messageTypesRegex.find(({ regExp }) => mimeType.match(regExp));
  const { type } = matchFromRegex || DEFAULT_ATTACHMENT_TYPE;
  return type;
};

export const getMessageType = ({ attachments, isMandatory, form }) => {
  if (form) {
    return MESSAGE_TYPES.FORM;
  }
  if (isMandatory) {
    return MESSAGE_TYPES.ACKNOWLEDGE;
  }

  if (attachments?.length === 1) {
    return getTypeFromAttachments(attachments);
  }

  if (attachments?.length > 1) {
    return MESSAGE_TYPES.MULTIPLE;
  }

  return MESSAGE_TYPES.TEXT;
};
